<template>
  <div class="admin-dashboard">
    <AdminHeader />
    <main>
      <b-container>
        <div class="mt-5">
          <b-card>
            <b-card-body class="p-0">
              <b-card-title>Manage Settings</b-card-title>
              <b-alert :show="!!errorMessage" variant="danger">{{
                errorMessage
              }}</b-alert>

              <b-form @submit="onSubmit">
                <b-row class="mb-3">
                  <b-col sm="4">
                    <label class="font-weight-bold pt-0" for="input-year"
                      >Election Year :</label
                    >
                  </b-col>
                  <b-col sm="2">
                    <b-form-input
                      id="input-year"
                      type="number"
                      v-model="form.electionYear"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col sm="4">
                    <label class="font-weight-bold pt-0"
                      >Last Date of Endorsement Submission :</label
                    >
                  </b-col>
                  <b-col md="8">
                    <b-input-group>
                      <b-form-datepicker
                        v-model="form.lastDateOfEndorsementSubmission"
                      ></b-form-datepicker>
                      <b-form-timepicker
                        v-model="form.lastTimeOfEndorsementSubmission"
                      ></b-form-timepicker>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col sm="4">
                    <label class="font-weight-bold pt-0" for="input-year"
                      >Last Date of Voting :</label
                    >
                  </b-col>
                  <b-col md="8">
                    <b-input-group>
                      <b-form-datepicker
                        v-model="form.lastDateOfVoting"
                      ></b-form-datepicker>
                      <b-form-timepicker
                        v-model="form.lastTimeOfVoting"
                      ></b-form-timepicker>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-button
                  v-if="isSaving"
                  type="button"
                  variant="primary"
                  disabled
                >
                  <b-spinner small></b-spinner>
                  <span> Saving...</span></b-button
                >
                <b-button v-else type="submit" variant="primary">
                  Save Settings</b-button
                >
              </b-form>
            </b-card-body>
          </b-card>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
import AdminHeader from "@/components/admin/Header.vue";

export default {
  name: "ManageSettings",
  components: {
    AdminHeader,
  },
  data() {
    return {
      form: {
        id: 0,
        electionYear: "",
        lastDateOfVoting: "2022-06-02",
        lastTimeOfVoting: "",
        lastDateOfEndorsementSubmission: "",
        lastTimeOfEndorsementSubmission: "",
      },
      loading: false,
      errorMessage: "",
      isSaving: false,
    };
  },
  computed: {},
  async mounted() {
    await this.$store.dispatch("settings/getSettings");
    const settings = this.$store.state.settings.item;

    this.form.id = settings.id;
    this.form.electionYear = settings.electionYear;

    const lastDateVoting = new Date(settings.lastDateOfVoting);
    const lastDateOfEndorsementSubmission = new Date(
      settings.lastDateOfEndorsementSubmission
    );

    this.form.lastDateOfVoting = `${lastDateVoting.getFullYear()}-${
      lastDateVoting.getMonth() + 1
    }-${lastDateVoting.getDate()}`;
    this.form.lastTimeOfVoting = `${lastDateVoting.getHours()}:${lastDateVoting.getMinutes()}`;

    this.form.lastDateOfEndorsementSubmission = `${lastDateOfEndorsementSubmission.getFullYear()}-${
      lastDateOfEndorsementSubmission.getMonth() + 1
    }-${lastDateOfEndorsementSubmission.getDate()}`;
    this.form.lastTimeOfEndorsementSubmission = `${lastDateOfEndorsementSubmission.getHours()}:${lastDateOfEndorsementSubmission.getMinutes()}`;
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.isSaving = true;
      const lastDateOfEndorsementSubmission = new Date(
        this.form.lastDateOfEndorsementSubmission +
          " " +
          this.form.lastTimeOfEndorsementSubmission
      );
      const lastDateOfVoting = new Date(
        this.form.lastDateOfVoting + " " + this.form.lastTimeOfVoting
      );
      const payload = {
        id: this.form.id,
        electionYear: this.form.electionYear,
        lastDateOfEndorsementSubmission: lastDateOfEndorsementSubmission,
        lastDateOfVoting: lastDateOfVoting,
      };
      setTimeout(async () => {
        await this.$store.dispatch("settings/updateSettings", payload);
        this.isSaving = false;
      }, 500);
    },
  },
};
</script>
